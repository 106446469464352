// Custom variable
$custom-bg: #faf1ea;
$primary-accent: #f37c48;  // New variable for the accent color
$text-grey: #333333;  // New variable for text color

// Font family override
$font-family-sans-serif: 'Bebas Neue', system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
$font-family-hero-text: 'Bangers', system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
$font-family-paragraph-text: 'Bebas Neue', sans-serif;

// Override Bootstrap variables
$body-bg: $custom-bg;
$navbar-light-bg: $custom-bg;  // Correct variable for Bootstrap navbar background
$light: $custom-bg;  // Override the bg-light class value

// Import all of Bootstrap
@import "../node_modules/bootstrap/scss/bootstrap";

// Custom styles
.hero-section {
    padding: 12rem 0;
    position: relative;
    overflow: hidden;  // To contain the oval and images
    
    &::before {
        content: '';
        position: absolute;
        width: 1600px;
        height: 870px;
        background-color: #0C5C6D;
        border-radius: 50%;
        left: 50%;
        top: 50%;
        transform: translate(calc(-50% + 170px), calc(-50% - 140px));  // Center then offset
        z-index: -1;  // Place behind content
    }
    
    .hero-title {
        font-family: 'Bangers', cursive;
        font-size: 4rem;
        color: #97E8BF;
        max-width: 600px;
        margin-bottom: 3rem;
    }

    .postcode-search {
        max-width: 400px;
        margin: 0 auto;
        text-align: center;

        .postcode-label {
            font-family: 'Bebas Neue', sans-serif;
            font-size: 1.875rem;
            color: white;
            margin-bottom: 0.5rem;
            display: block;
        }

        .search-wrapper {
            position: relative;
            
            .search-icon {
                position: absolute;
                left: 1rem;
                top: 50%;
                transform: translateY(-50%);
                z-index: 2;
            }

            .form-control {
                padding-left: 3rem;
                height: 66px;
                border-radius: 50rem;
                border: none;
                background-color: white;
                font-family: 'Bebas Neue', sans-serif;
                font-size: 1.2rem;
                color: $text-grey;
                padding-right: 120px;

                &::placeholder {
                    color: #333333;
                }

                &:focus {
                    outline: none;
                    box-shadow: none;
                }
            }

            .shop-button {
                position: absolute;
                right: 8px;
                top: 50%;
                transform: translateY(-50%);
                height: 50px;
                background-color: #97E8BF;
                color: #0C5C6D;
                font-family: 'Bebas Neue', sans-serif;
                font-size: 1.55rem;
                border: none;
                border-radius: 50rem;
                padding: 0 2rem;
                z-index: 2;
                transition: background-color 0.2s ease;

                &:hover {
                    background-color: darken(#97E8BF, 5%);
                }
            }
        }
    }

    .hero-dog-left {
        position: absolute;
        left: 0;
        bottom: 0;
        z-index: 1;
        max-height:530px;
    }

    .hero-dog-right {
        position: absolute;
        right: 0;
        bottom: 0;
        z-index: 1;
        max-height:580px
    }
}

.featured-product-card {
    transition: transform 0.3s ease;
    &:hover {
        transform: translateY(-5px);
        box-shadow: 0 10px 20px rgba(0,0,0,0.1);
    }
}

.category-card-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 60px;
}

.category-card {
    height: 130px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #E6CEC1;
    transition: all 0.3s ease;
    border-radius: 8px;
    position: relative;
    
    &:hover {
        transform: translateY(-5px);
    }
}

.category-title {
    color: $text-grey;
    text-align: center;
    font-size: 1.875rem;
    position: absolute;
    bottom: 105px;
}

.category-icon {
    width: 80%;
    height: auto;
    object-fit: contain;
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
}

.navbar {
    height: 160px;
}

.navbar-logo {
    height: 64px;
}

// Custom hamburger menu styling
.navbar-toggler {
    border: none;
    display: block !important;
    padding: 0.5rem;
    
    &:focus {
        box-shadow: none;
    }
}

.navbar-toggler-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 40 40'%3e%3cpath stroke='%23f37c48' stroke-linecap='round' stroke-miterlimit='10' stroke-width='4' d='M4 8h32M4 20h32M4 32h32'/%3e%3c/svg%3e");
    width: 2em;
    height: 2em;
}

.navbar-brand {
    margin-left: 20px;
}

// Always collapse the navbar by default
.navbar-collapse {
    display: none !important;
    position: fixed;
    top: 0;
    left: 0;
    width: 20%;
    height: 100vh;
    background-color: $custom-bg;
    z-index: 1000;
    padding: 1rem;
    box-shadow: 5px 0 10px rgba(0,0,0,0.1);
    transform: translateX(-100%);
    transition: transform 0.3s ease;
    
    &.show {
        display: block !important;
        transform: translateX(0);
    }
    
    padding-top: calc(160px + 1rem);
}

// Search bar styling
.search-form {
  margin-left: 20px; // 20px to the right of the logo
  width: 66.67%; // 2/3 screen wide
  
  .form-control {
    background-color: white;
    color: $text-grey;
    font-family: 'Bebas Neue', sans-serif;
    border: 0px solid #ddd !important;
    border-radius: 50rem; // Fully rounded left side
    border-top-right-radius: 0; // Square right side
    border-bottom-right-radius: 0; // Square right side
    padding-left: calc(0.75rem + 5px); // Add 5px to the default padding
    font-size: 1.25rem;
    
    &::placeholder {
      color: lighten($text-grey, 50%);
      font-family: 'Bebas Neue', sans-serif;
    }
  }
  
  .btn-outline-primary {
    background-color: $primary-accent;
    color: white;
    border-color: $primary-accent;
    border-radius: 50% !important; // Force circular shape
    width: 42px !important; // Slightly larger width
    height: 42px !important; // Slightly larger height
    min-width: 42px !important; // Ensure minimum width
    min-height: 42px !important; // Ensure minimum height
    padding: 0 !important; // Remove all padding
    margin-left: -1px; // Adjust position to overlap with input
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    position: relative;
    z-index: 5;
    overflow: visible !important; // Ensure content isn't clipped
    
    &:hover {
      background-color: darken($primary-accent, 10%);
      border-color: darken($primary-accent, 10%);
      color: white;
    }
    
    // Remove the Unicode character
    &::after {
      content: none;
    }
    
    // Show the SVG again
    svg {
      display: block;
      width: 24px;
      height: 24px;
      margin: 0;
      padding: 0;
    }
  }
  
  // Override Bootstrap's input-group styling
  .input-group {
    overflow: visible; // Allow content to extend beyond the container

    .btn {
        margin-left: -20px;
    }
  }
}

// Side menu styling
.side-menu {
  position: fixed;
  top: 0;
  left: -20%; // Start off-screen
  width: 20%;
  height: 100vh;
  background-color: $custom-bg;
  z-index: 1050;
  transition: left 0.3s ease;
  box-shadow: 5px 0 10px rgba(0,0,0,0.1);
  padding: 3rem 0 0 0; // Remove horizontal padding from the container
  
  &.active {
    left: 0; // Slide in
  }
  
  // Close menu button
  .close-menu {
    position: relative;
    display: block;
    margin-left: 1.5rem; // Add left margin to the button
    margin-bottom: 2rem;
    width: 32px;
    height: 32px;
    background-color: $primary-accent;
    border-radius: 50%;
    cursor: pointer;
    transition: background-color 0.2s ease;
    
    &:hover {
      background-color: darken($primary-accent, 10%);
    }
    
    &::before,
    &::after {
      content: '';
      position: absolute;
      width: 16px;
      height: 2px;
      background-color: white;
      border-radius: 1px;
      top: 50%;
      left: 50%;
    }
    
    &::before {
      transform: translate(-50%, -50%) rotate(45deg);
    }
    
    &::after {
      transform: translate(-50%, -50%) rotate(-45deg);
    }
  }
  
  .side-menu-content {
    margin-top: 1rem;
    
    .navbar-nav {
      padding: 0;
      margin: 0;
      list-style: none;
      width: 100%;
      border-bottom: 1px solid #D8BCAB;
    }
    
    .nav-item {
      margin: 0;
      width: 100%;
      
      // Add dividers between items
      & + .nav-item {
        border-top: 1px solid #D8BCAB;
      }
      
      .nav-link {
        color: $text-grey;
        font-size: 1.875rem;
        padding: 0.5rem 1.5rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        
        &:hover {
          color: $primary-accent;
          
          .circle-play {
            border-color: $primary-accent;
            
            &::before {
              border-left-color: $primary-accent;
            }
          }
        }
        
        // Circle with play icon
        .circle-play {
          display: inline-block;
          width: 20px;
          height: 20px;
          min-width: 20px;
          border: 2px solid #D8BCAB;
          border-radius: 50%;
          position: relative;
          
          // Play triangle inside circle
          &::before {
            content: '';
            position: absolute;
            top: 50%;
            left: 46%;
            transform: translate(-25%, -50%);
            width: 0;
            height: 0;
            border-top: 5px solid transparent;
            border-bottom: 5px solid transparent;
            border-left: 7px solid #D8BCAB;
          }
        }
      }
    }
  }
}

// Overlay for side menu
.side-menu-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #D8BCAB99;
  z-index: 1040;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease, visibility 0.3s ease;
  
  &.active {
    opacity: 1;
    visibility: visible;
  }
}

// Prevent scrolling when menu is open
body.menu-open {
  overflow: hidden;
}

// Product listing sidebar
.col-md-3 {
  .sidebar-nav {
    padding: 0;
    margin: 0;
    list-style: none;
    width: 100%;
    border-bottom: 1px solid #D8BCAB;
    
    .sidebar-item {
      margin: 0;
      width: 100%;
      
      // Add dividers between items
      & + .sidebar-item {
        border-top: 1px solid #D8BCAB;
      }
      
      .sidebar-link {
        color: $text-grey;
        font-size: 2.15rem;
        font-weight: normal;
        padding: 0.5rem 1.5rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        text-decoration: none;
        
        &:hover, &.active {
          color: $primary-accent;
          
          .circle-play {
            border-color: $primary-accent;
            
            &::before {
              border-left-color: $primary-accent;
            }
          }
        }
        
        // Circle with play icon
        .circle-play {
          display: inline-block;
          width: 20px;
          height: 20px;
          min-width: 20px;
          border: 2px solid #D8BCAB;
          border-radius: 50%;
          position: relative;
          
          // Play triangle inside circle
          &::before {
            content: '';
            position: absolute;
            top: 50%;
            left: 45%;
            transform: translate(-25%, -50%);
            width: 0;
            height: 0;
            border-top: 5px solid transparent;
            border-bottom: 5px solid transparent;
            border-left: 7px solid #D8BCAB;
          }
        }
      }
    }
  }
}

// Product cards
.product-card {
  background-color: white;
  border-radius: 8px;
  height: 228px;
  padding: 0 15px;
  display: flex;
  flex-direction: column;
  box-shadow: 0 2px 5px rgba(0,0,0,0.05);
  transition: transform 0.2s ease, box-shadow 0.2s ease;
  overflow: hidden;
  
  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 5px 15px rgba(0,0,0,0.1);
  }
  
  a {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    text-decoration: none;
    color: $text-grey;
  }
  
  .product-image-container {
    margin-top: 16px;
    height: 108px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .product-image {
    max-height: 108px;
    max-width: 100%;
    object-fit: contain;
  }
  
  .product-title {
    margin-top: 12px;
    font-size: 1rem;
    text-align: left;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    line-height: 1.2;
    max-height: 2.4em;
    color: $text-grey;
    width: 100%;
  }
  
  .product-price {
    margin-top: auto;
    margin-bottom: 16px;
    font-weight: bold;
    color: $text-grey;
    font-size: 1.25rem;
    text-align: left;
    width: 100%;
  }
}

// Update row columns for products
.col-md-9 .row-cols-md-4 > .col {
  padding: 0.5rem;
}

// Breadcrumb styling
.breadcrumb {
  font-size: 1rem;
  font-family: 'Roboto', sans-serif;
  
  .breadcrumb-item + .breadcrumb-item::before {
    content: " > ";
    color: $text-grey;
  }
  
  .breadcrumb-item {
    a {
      color: $text-grey;
      text-decoration: none;
      
      &:hover {
        color: $primary-accent;
      }
    }
    
    &.active {
      color: $text-grey;
    }
  }
}

// Remove the clear button from search inputs
input[type="search"]::-webkit-search-cancel-button {
  -webkit-appearance: none;
  appearance: none;
  display: none;
}

// Browse all products button
.browse-all-btn {
  background-color: #97E8BF;
  color: #0C5C6D;
  border-radius: 50rem;
  padding: 0 2rem;
  font-weight: 500;
  border: none;
  transition: all 0.2s ease;
  height: 66px;
  font-size: 2rem;
  text-decoration: none;
  
  /* Reset all display and alignment properties */
  display: inline-block;
  line-height: 66px; /* Match the height exactly */
  text-align: center;
  vertical-align: middle;
  
  &:hover {
    background-color: darken(#97E8BF, 5%);
    color: #0C5C6D;
    transform: translateY(-2px);
  }
  
  &:active {
    transform: translateY(0);
  }
}

.full-width-border {
    box-shadow: 0 -8px 12px -6px rgba(0, 0, 0, 0.05);
    width: 100vw;
    position: relative;
    left: 50%;
    right: 50%;
    margin-left: -50vw;
    margin-right: -50vw;
    padding-top: 60px;
}

// Home page section styling
.container section:not(.hero-section) {
    position: relative;
    padding-top: 140px;
    
    > h2 {
        position: absolute;
        top: 0;
        left: 12px;
        font-size: 3rem;
        margin-bottom: 15px;
    }
    
    > p:not(.product-price):not(.product-title):not(.card-text) {
        position: absolute;
        top: 60px;
        left: 12px;
        font-family: 'Roboto', sans-serif;
        font-size: 1.5rem;
        margin-bottom: 50px;
    }
}

// Override with higher specificity
.container section.product-details-section {
    padding-top: 0;
}

// Add new styles for button spacing
.browse-all-container {
    margin-top: 90px;
    margin-bottom: 60px;
    text-align: center;
}

// Brands section styling
.brands-carousel {
    margin-top: 50px;
    display: flex;
    align-items: center;
    gap: 20px;

    .carousel-arrow {
        width: 38px;
        height: 38px;
        border-radius: 50%;
        background-color: #97E8BF;
        border: none;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        transition: background-color 0.2s ease;

        &:hover {
            background-color: darken(#97E8BF, 5%);
        }
    }

    .brands-container {
        flex: 1;
        display: flex;
        justify-content: center;
        gap: 20px;
        overflow: hidden;
    }

    .brand-logo {
        width: 200px;
        height: 120px;
        background: white;
        border-radius: 12px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 20px;

        img {
            max-width: 100%;
            max-height: 100%;
            object-fit: contain;
        }
    }
}

// Footer styling
.footer-container {
    background-color: #E6CEC1;
    padding-top: 25px;
    
    ul {
        margin-top: 15px;
        
        li {
            padding-bottom: 15px;
            
            a {
                color: #000000;
                font-family: 'Roboto', sans-serif;
                font-size: 1.125rem;
                
                &:hover {
                    color: $primary-accent;
                }
            }
        }
    }
    
    .text-end {
        color: $text-grey;
        font-size: 1rem;
    }
}

// Delivery Banner
.delivery-banner {
    background-color: #FFCAB3;
    height: 80px;
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: 3rem;

    .delivery-text {
        color: #F37C48;
        font-size: 2.7rem;
        font-family: 'Bangers', cursive;
        margin: 0;
        width: 100%;
        text-align: center;
    }
}

// Product Detail Page Styles
.product-title {
    font-family: 'Bebas Neue', sans-serif;
    font-size: 3rem;
    color: #333333;
}

.product-description {
    font-family: 'Roboto', sans-serif;
    font-size: 1.125rem;
    line-height: 1.6;
}

.read-more-btn {
    font-family: 'Roboto', sans-serif;
    font-size: 1.125rem;
    color: $primary-accent;
    text-decoration: underline;
    background: none;
    border: none;
    padding: 0;
    margin-top: 0.5rem;
    cursor: pointer;
    transition: color 0.2s ease;

    &:hover {
        color: darken($primary-accent, 10%);
    }
}

.variant-label, .quantity-label, .price-label {
    font-family: 'Bebas Neue', sans-serif;
    font-size: 1.7rem;
    color: #333333;
    display: block;
}

.custom-select-wrapper {
    position: relative;

    &::after {
        content: '';
        position: absolute;
        right: 8px;
        top: 50%;
        transform: translateY(-50%);
        width: 38px;
        height: 38px;
        background-color: #97E8BF;
        border-radius: 50%;
        pointer-events: none;
    }

    &::before {
        content: '';
        position: absolute;
        right: 22px;
        top: 50%;
        transform: translateY(-50%) rotate(45deg);
        width: 10px;
        height: 10px;
        border-right: 2px solid white;
        border-bottom: 2px solid white;
        z-index: 1;
        pointer-events: none;
        margin-top: -2px; // Adjust the arrow position slightly up
    }
}

.custom-select {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    width: 100%;
    padding: 12px 60px 12px 20px;
    font-family: 'Roboto', sans-serif;
    font-size: 1.125rem;
    background-color: white;
    border: 1px solid #dee2e6;
    border-radius: 50px;
    cursor: pointer;
}

.price-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.price-value {
    font-family: 'Roboto', sans-serif;
    font-size: 3.3rem;
    color: #333333;
}

.quantity-input-wrapper {
    position: relative;
    display: inline-flex;
    align-items: center;
    background-color: white;
    border-radius: 50px;
    padding: 5px;
    border: 1px solid #dee2e6;
}

.quantity-btn {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    border: none;
    background-color: #E6CEC1;
    position: relative;
    z-index: 1;

    &::before,
    &::after {
        content: '';
        position: absolute;
        background-color: white;
    }

    &.minus::before {
        width: 12px;
        height: 2px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    &.plus {
        &::before,
        &::after {
            background-color: white;
        }

        &::before {
            width: 12px;
            height: 2px;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }

        &::after {
            width: 2px;
            height: 12px;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }
}

.quantity-input {
    width: 60px;
    text-align: center;
    border: none;
    font-family: 'Roboto', sans-serif;
    font-size: 1.125rem;
    margin: 0 10px;
    -moz-appearance: textfield;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
}

.add-to-cart-btn {
    background-color: #97E8BF;
    color: #0C5C6D;
    border-radius: 50rem;
    padding: 0 2rem;
    font-family: 'Bebas Neue', sans-serif;
    font-size: 1.7rem;
    border: none;
    height: 66px;
    transition: all 0.2s ease;

    &:hover {
        background-color: #7ad1a6;
        transform: translateY(-2px);
    }

    &:active {
        transform: translateY(0);
    }
    
    &.out-of-stock {
        background-color: #cccccc;
        color: #666666;
        cursor: not-allowed;
        
        &:hover {
            background-color: #cccccc;
            transform: none;
        }
    }
}

.quantity-section {
    .quantity-label {
        font-family: 'Bebas Neue', sans-serif;
        font-size: 1.7rem;
        color: #333333;
    }
}

.price-container {
    text-align: left;
    
    .price-label {
        display: block;
        margin-bottom: 0.5rem;
    }
}

.add-to-cart-btn {
    width: 100%;
    margin-top: 1rem;
}

// 404 Error Page Styles
.error-container {
    min-height: calc(100vh - 160px - 250px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 200px 2rem;
    position: relative;
    margin-top: -3rem;

    .error-heading {
        font-family: 'Bangers', cursive;
        font-size: 5rem;
        color: $text-grey;
        margin-bottom: 1rem;
    }

    .error-subtext {
        font-family: 'Bebas Neue', sans-serif;
        font-size: 2.6rem;
        color: $text-grey;
        margin-bottom: 2rem;
    }

    .error-button {
        background-color: #97E8BF;
        color: #0C5C6D;
        border-radius: 50rem;
        padding: 0 2rem;
        font-family: 'Bebas Neue', sans-serif;
        font-size: 2rem;
        border: none;
        height: 66px;
        text-decoration: none;
        display: inline-flex;
        align-items: center;
        transition: all 0.2s ease;

        &:hover {
            background-color: darken(#97E8BF, 5%);
            transform: translateY(-2px);
            color: #0C5C6D;
        }
    }

    .error-image {
        position: absolute;
        bottom: -8rem;
        left: -3rem;
        max-width: 700px;
        height: auto;
        z-index: -1;
    }
}

// Success Page Styles
.success-container {
    min-height: calc(100vh - 160px - 250px);
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 100px 2rem;
    position: relative;
    z-index: -2;
    margin-bottom: 300px; // Increased space for bottom content

    .success-box {
        background-color: #0C5C6D;
        border-radius: 24px;
        width: 100%;
        max-width: 1200px;
        height: 460px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 2rem;
        margin-bottom: 2rem;

        .success-heading {
            font-family: 'Bangers', cursive;
            font-size: 5rem;
            color: #97E8BF;
            text-align: center;
            margin-bottom: 2rem;
        }

        .success-subtext {
            font-family: 'Bebas Neue', sans-serif;
            font-size: 3.125rem;
            color: white;
            text-align: center;
        }
    }

    .contact-text {
        align-self: flex-end;
        color: #0C5C6D;
        font-family: 'Roboto', sans-serif;
        font-weight: bold;
        font-size: 1.6rem;
        margin-bottom: 4rem;
        margin-right: calc((100% - 1000px) / 2);

        .whatsapp-icon {
            color: #5F95A5;
            margin-left: 0.5rem;
        }
    }
}

// Bottom content styling
.bottom-content {
    position: absolute;
    bottom: -300px; // Moved down further
    left: -3rem;
    display: flex;
    align-items: flex-end;
    gap: 2rem;
    z-index: -1;

    .success-image {
        max-width: 700px;
        height: auto;
        order: 1;

        @media (max-width: 768px) {
            max-width: 200px;
        }
    }

    .kiss-text {
        font-family: 'Roboto', sans-serif;
        font-size: 1.2rem;
        color: #0C5C6D;
        margin-bottom: 4rem;
        margin-left: -10rem;
        order: 2;
        font-weight: bold;
    }
}

// Error message styling
.error-message {
    display: none;
    color: #97E8BF;
    margin-top: 5px;
    font-family: 'Roboto', sans-serif;
    font-weight: bold;
    font-size: 1rem;
    text-align: center;
}

// Not Available Page Styles
.not-available-container {
    position: relative;
    width: 100vw;
    left: 50%;
    right: 50%;
    margin-left: -50vw;
    margin-right: -50vw;
    overflow: hidden; // This will cut off at the left edge
    padding: 100px 0;
    margin-top: -3rem;

    .container-inner {
        width: 100%;
        max-width: 1320px; // Bootstrap's default container width
        margin: 0 auto;
        padding: 0 2rem;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .not-available-heading {
        font-family: 'Bangers', cursive;
        font-size: 5rem;
        color: $text-grey;
        margin-bottom: 1rem;
        text-align: center;
    }

    .not-available-subtext {
        font-family: 'Bebas Neue', sans-serif;
        font-size: 2.6rem;
        color: $text-grey;
        margin-bottom: 2rem;
        text-align: center;
    }

    .notification-box {
        background-color: #F8EAE0;
        border-radius: 24px;
        padding: 2rem 5rem 2rem 5rem;
        width: 100%;
        max-width: 800px;
        margin-bottom: 2rem;
        z-index: 1;
        position: relative;

        .notification-heading {
            font-family: 'Bebas Neue', sans-serif;
            font-size: 2.5rem;
            color: $text-grey;
            margin-bottom: 1rem;
        }

        .notification-text {
            font-family: 'Roboto', sans-serif;
            font-size: 1.4rem;
            color: $text-grey;
            margin-bottom: 1.5rem;
        }

        .notify-form {
            position: relative;
            z-index: 2;
            
            input[type="email"] {
                position: relative;
                z-index: 3;
                width: 100%;
                height: 66px;
                border-radius: 50rem;
                border: none;
                padding: 0 2rem;
                font-family: 'Bebas Neue', sans-serif;
                font-size: 1.8rem;
                color: $text-grey;
                background-color: white;

                &::placeholder {
                    color: lighten($text-grey, 30%);
                }

                &:focus {
                    outline: none;
                    box-shadow: 0 0 0 2px #97E8BF;
                }
            }
        }
    }

    .notify-button {
        position: relative;
        left: calc(50% - 228px);
        transform: translateX(-50%);
        background-color: #97E8BF;
        color: #0C5C6D;
        border-radius: 50rem;
        padding: 0 2rem;
        font-family: 'Bebas Neue', sans-serif;
        font-size: 1.7rem;
        border: none;
        height: 66px;
        min-width: 120px;
        transition: all 0.2s ease;
        margin-bottom: 2rem;

        &:hover {
            background-color: darken(#97E8BF, 5%);
            transform: translateX(-50%) translateY(-2px);
        }

        &:active {
            transform: translateX(-50%) translateY(0);
        }
    }

    .not-available-image {
        position: absolute;
        bottom: -30rem;
        right: 75rem;
        max-width: 600px;
        height: auto;
        z-index: 1;

        @media (max-width: 768px) {
            max-width: 300px;
        }
    }

    .notify-message {
        display: none;
        color: #0C5C6D;
        margin-top: 5px;
        font-family: 'Roboto', sans-serif;
        font-weight: bold;
        font-size: 1rem;
        text-align: center;
    }
}

// Categories page styles
.categories-page-header {
    text-align: center;
    margin-bottom: 3rem;
    margin-top: 2rem;
}

.categories-title {
    font-family: 'Bangers', cursive;
    font-size: 5rem; // 80px
    color: $text-grey;
    margin-bottom: 1.25rem; // 20px
}

// Adjust category cards for 3 in a row
.col-md-4 .category-card-container {
    margin-top: 60px;
    margin-bottom: 0px;
    
    .category-card {
        height: 190px; // Taller cards for the categories page
        
        .category-icon {
            top: 30%; // Reposition the icon for the taller cards
        }
    }
    
    .category-title {
        bottom: 0px; // Position the title below the card instead of overlapping
        position: relative; // Change from absolute to relative positioning
        margin-top: 15px; // Add some space between card and title
        text-align: center;
        font-size: 2rem;
    }
}

// Brands page styles
.brands-page-header {
    text-align: center;
    margin-bottom: 3rem;
    margin-top: 2rem;
}

.brands-title {
    font-family: 'Bangers', cursive;
    font-size: 5rem; // 80px
    color: $text-grey;
    margin-bottom: 1.25rem; // 20px
}

.brand-card {
    background-color: white;
    border-radius: 12px;
    height: 180px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
    box-shadow: 0 2px 5px rgba(0,0,0,0.05);
    transition: transform 0.2s ease, box-shadow 0.2s ease;
    
    &:hover {
        transform: translateY(-5px);
        box-shadow: 0 5px 15px rgba(0,0,0,0.1);
    }
    
    img {
        max-width: 100%;
        max-height: 100%;
        object-fit: contain;
    }
}

// Terms and Conditions Styles
.terms-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 2rem;
    color: $text-grey;
}

// Full-width white background for terms page
body.terms-page {
    background-color: white;
}

.terms-main-heading {
    font-family: 'Bebas Neue', sans-serif;
    font-size: 48px;
    margin-bottom: 2rem;
    color: $text-grey;
}

.terms-subheading {
    font-family: 'Roboto', sans-serif;
    font-weight: bold;
    font-size: 28px;
    margin-top: 2rem;
    margin-bottom: 1rem;
    color: $text-grey;
}

.terms-paragraph {
    font-family: 'Roboto', sans-serif;
    font-size: 24px;
    line-height: 1.5;
    margin-bottom: 1rem;
    color: $text-grey;
}

// Style for lists in terms and conditions
.terms-container ul,
.terms-container ol {
    font-family: 'Roboto', sans-serif;
    font-size: 24px;
    line-height: 1.5;
    margin-bottom: 1rem;
    color: $text-grey;
    padding-left: 2rem;
}

.terms-container li {
    margin-bottom: 0.5rem;
}

// Cookie Consent Styling
.cookie-banner {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: $custom-bg; /* Using your brand background color */
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  padding: 1rem;
  font-family: 'Roboto', sans-serif;
  border-top: 1px solid #D8BCAB; /* Adding a subtle border using your brand color */
}

.cookie-banner-content {
  max-width: 1200px;
  margin: 0 auto;
  padding: 1rem;
  
  h3 {
    font-family: 'Bebas Neue', sans-serif;
    font-size: 1.8rem;
    color: $text-grey;
    margin-bottom: 0.5rem;
  }
  
  p {
    font-family: 'Roboto', sans-serif;
    color: $text-grey;
    margin-bottom: 1rem;
  }
}

.cookie-banner-buttons {
  display: flex;
  gap: 1rem;
  margin-top: 1rem;
  justify-content: flex-end;
}

.cookie-modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #D8BCAB99; /* Matching the side-menu-overlay background */
  z-index: 1040; /* Matching the side-menu-overlay z-index */
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'Roboto', sans-serif;
  opacity: 1;
  visibility: visible;
  transition: opacity 0.3s ease, visibility 0.3s ease;
  
  &.hidden {
    opacity: 0;
    visibility: hidden;
  }
}

.cookie-modal-content {
  background-color: $custom-bg; /* Using your brand background color */
  border-radius: 8px;
  max-width: 600px;
  width: 90%;
  max-height: 90vh;
  overflow-y: auto;
  box-shadow: 5px 0 10px rgba(0,0,0,0.1); /* Matching side menu shadow */
}

.cookie-modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  border-bottom: 1px solid #eee;
}

.cookie-modal-close {
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  color: $text-grey;
}

.cookie-modal-body {
  padding: 1rem;
}

.cookie-modal-footer {
  padding: 1rem;
  border-top: 1px solid #eee;
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
}

.cookie-categories {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-top: 1rem;
}

.cookie-category {
  border: 1px solid #E6CEC1; /* Using a color from your palette */
  border-radius: 4px;
  padding: 1rem;
  background-color: white;
}

.cookie-category-header {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-bottom: 0.5rem;
}

.cookie-toggle {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 24px;
}

.cookie-toggle input {
  opacity: 0;
  width: 0;
  height: 0;
}

.cookie-toggle-slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: .4s;
  border-radius: 24px;
}

.cookie-toggle-slider:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: .4s;
  border-radius: 50%;
}

.cookie-toggle input:checked + .cookie-toggle-slider {
  background-color: #97E8BF; /* Using your brand accent color */
}

.cookie-toggle input:checked + .cookie-toggle-slider:before {
  transform: translateX(26px);
}

.cookie-btn {
  padding: 0.5rem 1rem;
  border-radius: 4px;
  border: none;
  cursor: pointer;
  font-weight: bold;
  font-family: 'Bebas Neue', sans-serif;
  font-size: 1.2rem;
}

.cookie-btn-primary {
  background-color: #97E8BF; /* Using your brand accent color */
  color: #0C5C6D; /* Using your brand text color */
}

.cookie-btn-secondary {
  background-color: white; /* Changed to white */
  color: $text-grey;
  border: 1px solid #D8BCAB; /* Adding a subtle border */
}

.cookie-btn-primary:hover {
  background-color: darken(#97E8BF, 5%);
}

.cookie-btn-secondary:hover {
  background-color: #f9f9f9; /* Subtle hover effect */
}

// Prevent scrolling when cookie modal is open
body.cookie-modal-open {
  overflow: hidden;
}

// Cookie visibility classes
.cookie-element-hidden {
  display: none;
}

// Product Details Tabs Section
.product-details-section {
    position: relative;
    margin-top: 30px;
}

.product-info-tabs {
    margin-top: 30px;
}

.product-tab-buttons {
    display: flex;
    gap: 30px;
    margin-bottom: 20px;
}

.product-tab-btn {
    background: none;
    border: none;
    font-family: 'Bebas Neue', sans-serif;
    font-size: 48px;
    color: #333333;
    padding: 0;
    cursor: pointer;
    transition: color 0.2s ease;
    
    &:hover {
        color: $primary-accent;
    }
    
    &.active {
        color: $primary-accent;
    }
}

.product-tab-content-container {
    background-color: #F8EAE0;
    border: 2px dashed #E6CEC1;
    border-radius: 7px;
    padding: 30px;
}

.product-tab-content {
    display: none;
    font-family: 'Roboto', sans-serif;
    
    &.active {
        display: block;
    }
    
    p {
        font-size: 1.125rem;
        line-height: 1.6;
        margin-bottom: 1rem;
    }
    
    h3 {
        font-family: 'Bebas Neue', sans-serif;
        font-size: 1.875rem;
        color: $text-grey;
        margin-bottom: 1rem;
    }
    
    ul {
        margin-bottom: 1.5rem;
        padding-left: 1.5rem;
        
        li {
            font-size: 1.125rem;
            line-height: 1.6;
            margin-bottom: 0.5rem;
        }
    }
    
    strong, u {
        font-weight: bold;
    }
    
    .table {
        background-color: white;
        
        th {
            font-family: 'Roboto', sans-serif;
            font-size: 1.25rem;
            background-color: #E6CEC1;
            color: $text-grey;
        }
        
        td {
            font-family: 'Roboto', sans-serif;
            font-size: 1.125rem;
        }
    }
}

.features-list, .instructions-list {
    list-style-type: disc;
}